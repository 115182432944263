<template>
  <div class="other-notice">
    <div class="other-body">
      <img src="./img/contact.jpeg" alt="" />
      <div
        :class="[
          'submit',
          {
            active:
              returnValue.auditStatus == undefined ||
              returnValue.auditStatus == 2,
          },
        ]"
        @click="submit"
      >
        {{
          returnValue.auditStatus == undefined
            ? "提交小和互动截图，赢取甜蜜值"
            : setStatusMps[returnValue.auditStatus]
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { postInteractionUrl, myInteractionUrl } from "./api.js";
import { statusMps, setStatusMps, setStatusMpsOps } from "./map.js";
import { toRegister, gloabalCount } from "@/utils/common.js";

var moment = require("moment");
export default {
  name: "talent",
  data() {
    return {
      setStatusMps,
      returnData: [],
      returnValue: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    if (this.userId && this.userId != 0 && this.userId != undefined) {
      this.getDatas();
    }
    gloabalCount("", 20, 1);
  },
  methods: {
    async submit() {
      if (await toRegister(this.$route.path, this.$route.query, "运动打卡")) {
        if (
          this.returnValue.auditStatus == undefined ||
          this.returnValue.auditStatus == 2
        ) {
          this.$router.push({
            name: "interaction",
            query: {
              id: this.returnValue.id,
            },
          });
        }
      }
    },
    getDatas() {
      let params = {
        communityId: this.communityId,
        userId: this.userId,
      };
      this.$axios.get(`${myInteractionUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          this.returnData = res.data.records;
          if (this.returnData[0]) {
            this.returnValue = this.returnData[0];
          }
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.other-notice {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .other-body {
    padding-bottom: 100px;
  }
  img {
    width: 100%;
    vertical-align: middle;
  }
  &::after {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
  .submit {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    width: 100%;
    height: 96px;
    // background: #178af7;
    background: #ccc;
    text-align: center;
    font-size: 32px;
    line-height: 96px;
    color: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
  .active {
    background: #178af7;
  }
}
</style>
<style lang="less">
.other-notice {
}
</style>
