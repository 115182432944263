var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"other-notice"},[_c('div',{staticClass:"other-body"},[_c('img',{attrs:{"src":require("./img/contact.jpeg"),"alt":""}}),_c('div',{class:[
        'submit',
        {
          active:
            _vm.returnValue.auditStatus == undefined ||
            _vm.returnValue.auditStatus == 2,
        },
      ],on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.returnValue.auditStatus == undefined ? "提交小和互动截图，赢取甜蜜值" : _vm.setStatusMps[_vm.returnValue.auditStatus])+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }